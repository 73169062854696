.experience__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    padding-bottom: 2rem;
}
.experience__container > div {
    background-color: var(--c-brown);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 3px solid var(--c-blue);
    transition: var(--trans);
}
.experience__container > div:hover {
    background: transparent;
    border: 3px solid var(--c-blue);
    cursor: default;
}
.experience__container > div:hover .exp__content{
    color: var(--c-blue);
}
.experience__container > div h3 {
    text-align: center;
    color: var(--c-primary);
    margin-bottom: 2rem;
}
.exp__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: auto;
    color: var(--c-bg);
    align-items: center;
}
.exp__icon{
    color: var(--c-blue);
}
@media screen and (max-width: 1024px){
    .experience__container {
        grid-template-columns: 1fr;
    }
    .experience__container > div {
        width: 80%;
        padding: auto;
        margin: 0 auto;
    }
}
@media screen and (max-width: 600px){
    .experience__container > div {
        padding: 1rem;
        
    }
    .experience__container {
        grid-template-columns: 1fr;
        
    }
    .experience__container > div {
        width: 80%;
        padding: 3rem 3rem;
        margin: 2rem auto;
        left: 50%;
    }
}