.contact__container{
    background: var(--c-blue);
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 3px solid var(--c-brown);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}
.con{
    margin: 1%;
}
@media screen and (max-width: 1024px){
    .contact__container{
        flex-wrap: wrap;
        row-gap: 10px;
        padding-bottom: 30%;
    }
    .con{
        margin: 0 0;
        
        
    }
}