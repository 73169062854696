h2{
    color: var(--c-blue);
    padding-top: 1rem;
}
.about__cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    padding-bottom: 2rem;
    
}
.about__card {
    background: var(--c-brown);
    border: 3px solid var(--c-blue);
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--trans);
}
.about__card:hover{
    background: transparent;
    cursor: default;
}
.about__card:hover small{
    background: transparent;
    cursor: default;
    color: var(--c-blue);
}
.about__card small {
    color: var(--c-bg);
}
.about__icon {
    color: var(--c-blue);
    font-size: 1.4rem;
}
.about__content p {
    margin: 2rem 2.6rem;
    color: var(--c-text);
    text-align: justify;
}


small{
    color: var(--c-blue);
}

@media screen and (max-width: 1024px){
    .about__container {
        grid-template-columns: 1fr 1fr;
        gap: 0;
    }
    .about__cards {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 600px){
    .about__cards {
        grid-template-columns: 1fr;
    }
    .about__content {
        text-align: center;
    }
}
