header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    padding-top: 7rem;
    text-align: center;
    height: 100%;
    position: relative;
}

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: .9rem;
}
h5 {
    color: var(--c-bg-varient);
}
h1 {
    color: var(--c-primary);
}


@media screen and (max-width: 600px){
    .header__container {
        height: 100%;
    }
    .scroll__down {
        display: none;
    }
}