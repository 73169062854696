nav {
    background: var(--c-brown);
    width: max-content;
    display: block;
    padding: 0.5rem 1.3rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50%;
    left: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    border-radius: 3rem;
    border: 3px solid var(--c-blue);
    backdrop-filter: blur(15px);
    transition: var(--trans);
}
nav a {
    background: transparent;
    margin: 0.9rem 0.9rem;
    border-radius: 50%;
    display: block;
    color: var(--c-gray);
    font-size: 1.1rem;
}
nav a p {
    display: none;
    text-decoration: underline;
    font-size: 1rem;
    padding: 0;
    margin: 0;
    text-decoration-color: var(--c-brown);
    
}

nav a:hover .icons{
    color: var(--c-blue);
    transition: var(--trans);
}
nav a.active {
    color: var(--c-blue);
}

@media screen and (max-width: 1024px){
    nav{
        flex-direction: row;
        bottom: 2rem;
        left: 50%;

    }
    nav a {
        padding: 0;
        height: min-content;
    }
    nav a.active {
        background: none;
        color: var(--c-gray);
        text-decoration: underline;
    }
}